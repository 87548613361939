import { useContext } from "react";
import GlobalContext, { ActionType, LS_CART_KEY } from "../helpers/context";
import { useNavigate } from "react-router-dom";
import { IProduct } from "../interfaces";

export default function Cart(){
  const {state, dispatch} = useContext(GlobalContext);
  const navigate = useNavigate();

  const handleCheckout = () => {
    //navigate('/checkout'); 
    try {
      localStorage.setItem(LS_CART_KEY, JSON.stringify([]));
      dispatch({type: ActionType.SET_CART, payload: []});
      alert("Thank you for your purchasing");
    } catch (error) {
      
    }
  };

  // Handle increasing quantity
  const increaseQuantity = (index: number) => {
    const newCart = [...cart];
    newCart[index].quantity! += 1;
    dispatch({type: ActionType.SET_CART, payload: newCart});
  };

  // Handle decreasing quantity
  const decreaseQuantity = (index: number) => {
    const newCart = [...cart];
    if (newCart[index].quantity! > 1) {
      newCart[index].quantity! -= 1;
      dispatch({type: ActionType.SET_CART, payload: newCart});
    }
  };

  // Handle deleting a product
  const deleteProduct = (index: number) => {
    const newCart = [...cart];
    newCart.splice(index, 1); // Remove the product from the cart
    dispatch({ type: ActionType.SET_CART, payload: newCart });
  };

  const cart: IProduct[] = state.cart;
  
  // Calculate totals
  const taxRate = 0.1; // 10% tax
  const totalPrice = cart.reduce((acc, product) => acc + product.price * product.quantity!, 0);
  const tax = totalPrice * taxRate;
  const finalTotalPrice = totalPrice + tax;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4 text-center">Shopping Cart</h1>

      {cart.length > 0 ? (
        <div>
          <ul className="mb-4">
            {cart.map((product, index) => (
              <li
              key={index}
              className="border p-4 mb-2 rounded flex justify-between items-center"
            >
              <div className="flex items-center space-x-4">
                <span className="w-40">{product.name}</span>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => decreaseQuantity(index)}
                  className="bg-gray-300 text-black py-1 px-2 rounded mr-2"
                >
                  -
                </button>
                <span className="px-4">{product.quantity}</span>
                <button
                  onClick={() => increaseQuantity(index)}
                  className="bg-gray-300 text-black py-1 px-2 rounded ml-2"
                >
                  +
                </button>
              </div>
              <span className="w-20 text-right">${product.price.toFixed(2)}</span>
              <button
                onClick={() => deleteProduct(index)}
                className="bg-red-500 text-white py-1 px-2 rounded ml-4 hover:bg-red-600"
              >
                Delete
              </button>
            </li>
              
            ))}
          </ul>

          {/* Tax and Total Price Calculation */}
          <div className="text-right mb-4 p-4 border-t">
            <div className="flex justify-between">
              <span className="text-lg font-medium">Subtotal:</span>
              <span className="text-lg">${totalPrice.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-lg font-medium">Tax (10%):</span>
              <span className="text-lg">${tax.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mt-4 text-xl font-semibold">
              <span>Total Price:</span>
              <span>${finalTotalPrice.toFixed(2)}</span>
            </div>
          </div>

          <div className="flex justify-between mt-6">
            {/* Back to Product List Button */}
            <button
              onClick={() => navigate('/')}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Back to Product List
            </button>

            {/* Proceed to Checkout Button */}
            <button
              onClick={handleCheckout}
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      ) : (
        
        <div className="flex justify-between mt-6">
            <p>Your cart is empty.</p>
            <button
              onClick={() => navigate('/')}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Back to Product List
            </button>
        </div>
      )}
    </div>
  );
}