
import React, { useContext, useState } from 'react';
import { IProduct } from '../interfaces';
import Product from './Product';
import { products } from './data';
import { useNavigate } from 'react-router-dom';
import { FaShoppingCart } from "react-icons/fa";

import GlobalContext, { ActionType, LS_CART_KEY } from '../helpers/context';
export default function ProductList(){
  const {state, dispatch} = useContext(GlobalContext);
  const [cart, setCart] = useState<IProduct[]>(state.cart);
  const navigate = useNavigate();
  const addToCart = (product: IProduct) => {
    const foundIndex = cart.findIndex( x => x.id === product.id);
    let newCart: IProduct[] = [...cart];
    if(foundIndex !== -1){
      newCart[foundIndex].quantity! += 1;
    }else{
      product.quantity = 1;
      newCart.push(product);
    }
    
    try {
      setCart(newCart);
      localStorage.setItem(LS_CART_KEY, JSON.stringify(newCart));
      dispatch({type: ActionType.SET_CART, payload: newCart});
    } catch (error) {
      
    }
  };

  const goToCart = () =>{
    navigate('/cart')
  }
  const cartItemCount = state.cart.reduce((acc: number, item: IProduct) => acc + 1, 0);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-center flex-1">Product List</h1>
        {/* Button to move to Cart page */}
        <button
        onClick={goToCart}
        className="ml-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 flex items-center"
      >
        <FaShoppingCart className="mr-2" /> {/* Cart icon */}
        <span>Go to Cart</span>
        {cartItemCount > 0 && (
          <span className="ml-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
            {cartItemCount}
          </span>
        )}
      </button>
      </div>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {products.map((product) => <Product key={product.id} data={product} addToCart={addToCart}/>)}
      </ul>
    </div>
  );
};
