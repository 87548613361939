import { IProduct } from "../interfaces";

interface ProductProps {
  data: IProduct;
  addToCart: (data: IProduct) => void;
}

export default function Product({ data, addToCart }: ProductProps) {
  const { id, name, image, price } = data;

  return (
    <li key={id} className="border p-4 rounded flex flex-col justify-between h-full">
      <div>
        <img src={image} alt={name} className="w-full h-48 object-cover mb-4" />
        <div className="flex justify-between items-center mb-4">
          <span className="text-lg">{name}</span>
          <span className="text-lg">${price.toFixed(2)}</span>
        </div>
      </div>
      <button
        onClick={() => addToCart(data)}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full mt-auto"
      >
        Add to Cart
      </button>
    </li>
  );
}
