import { useEffect, useReducer, useState } from 'react';
import './App.css';
import Home from './components/Home';
import GlobalContext, { ActionType, LS_CART_KEY, reducer } from './helpers/context';
import { ActivityIndicator } from 'typetailui';
import TagManager from 'react-gtm-module';

function App() {
  const [state, dispatch] = useReducer(reducer, { cart: [] });
  const [loading, setLoading] = useState(true);
  async function loadCart() {
    try {
      setLoading(true);
      const res = localStorage.getItem(LS_CART_KEY);
      if (res) {
        const obj = JSON.parse(res);
        dispatch({ type: ActionType.SET_CART, payload: obj });
      }
    } catch (error) {}
    setLoading(false);
  }
  useEffect(() => {
    loadCart();
    // Initialize GTM with your Container ID
    const tagManagerArgs = {
      gtmId: 'GTM-WWNLLRK5' // Replace with your GTM Container ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  if (loading) {
    return <ActivityIndicator />;
  }
  return(
    <GlobalContext.Provider value={{state, dispatch}}>
      <Home/>
    </GlobalContext.Provider>
  )
}

export default App;
