import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import ProductList from "./ProductList";
import NotFoundError from "./NotFoundError";
import Contact from "./Contact";
import Cart from "./Cart";

const router = createBrowserRouter([
  {
    path: "",
    element: <ProductList/>
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/cart",
    element: <Cart />
  },
  {
    path: "*",
    element: <NotFoundError />
  }
]);

export default function Home() {
  return <RouterProvider router={router} />;
}
