import { createContext } from "react";
import { IGlobalContext } from "../interfaces";


const GlobalContext = createContext<IGlobalContext>({state: null, dispatch: null});
export default GlobalContext;
export const ActionType = {
  SET_STATE: "Set State",
  SET_USER: "Set User",
  SET_CART: "Set Cart"
}

export const LS_CART_KEY = "@ami-wholesale-cart";


export interface Action {
  type: string,
  payload?: Object
}
export function reducer(state: any, action: Action): any{
  switch(action.type){
    case ActionType.SET_STATE:
      return {...action.payload};
    case ActionType.SET_USER:
      return {...state, user: action.payload};
    case ActionType.SET_CART:
      return {...state, cart: action.payload};
    
    default:
      return state;
  }
}

