export const products = [
  { id: 1, name: 'Apple iPhone 13', price: 799, image: 'https://picsum.photos/200?random=1' },
  { id: 2, name: 'Samsung Galaxy S21', price: 699, image: 'https://picsum.photos/200?random=2' },
  { id: 3, name: 'Sony WH-1000XM4 Headphones', price: 349, image: 'https://picsum.photos/200?random=3' },
  { id: 4, name: 'Dell XPS 13 Laptop', price: 999, image: 'https://picsum.photos/200?random=4' },
  { id: 5, name: 'Apple MacBook Pro', price: 1299, image: 'https://picsum.photos/200?random=5' },
  { id: 6, name: 'Nintendo Switch', price: 299, image: 'https://picsum.photos/200?random=6' },
  { id: 7, name: 'Bose SoundLink Revolve', price: 199, image: 'https://picsum.photos/200?random=7' },
  { id: 8, name: 'Google Pixel 6', price: 599, image: 'https://picsum.photos/200?random=8' },
  { id: 9, name: 'Apple iPad Air', price: 599, image: 'https://picsum.photos/200?random=9' },
  { id: 10, name: 'Sony PlayStation 5', price: 499, image: 'https://picsum.photos/200?random=10' },
  { id: 11, name: 'Microsoft Xbox Series X', price: 499, image: 'https://picsum.photos/200?random=11' },
  { id: 12, name: 'Fitbit Charge 5', price: 179, image: 'https://picsum.photos/200?random=12' },
  { id: 13, name: 'GoPro HERO9 Black', price: 399, image: 'https://picsum.photos/200?random=13' },
  { id: 14, name: 'Dyson V11 Vacuum Cleaner', price: 599, image: 'https://picsum.photos/200?random=14' },
  { id: 15, name: 'Apple AirPods Pro', price: 249, image: 'https://picsum.photos/200?random=15' },
  { id: 16, name: 'Oculus Quest 2', price: 399, image: 'https://picsum.photos/200?random=16' },
  { id: 17, name: 'Amazon Echo Show 8', price: 129, image: 'https://picsum.photos/200?random=17' },
  { id: 18, name: 'Samsung 65-Inch QLED TV', price: 1199, image: 'https://picsum.photos/200?random=18' },
  { id: 19, name: 'Roku Streaming Stick 4K', price: 49, image: 'https://picsum.photos/200?random=19' },
  { id: 20, name: 'Nikon D850 Camera', price: 2999, image: 'https://picsum.photos/200?random=20' },
];
